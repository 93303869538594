import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ExternalButtonLink } from "components/buttons"

const StyledGetTested = styled.section`
  padding: 20px 0 0 0;
  text-align: center;
  background-color: ${colors.purpleDark};
  color: ${colors.charcoal};

  ${breakpoint.medium`
    padding: 40px 0;
  `}

	h2 {
		text-align: center;
		font-size: 32px;
		padding: 20px 0 30px;
  		color: ${colors.white};

      ${breakpoint.medium`
        font-size: 38px;
		padding: 40px 0;
      `}
	 }

`	 

	
const StyledContainer = styled(Container)`
  max-width: 1150px;
  background-color: ${colors.white};
  padding: 0 0 30px 0;
  margin: 0 auto;
  
  
  	h3 {
  		font-size: 20px;
		padding-block: 30px 0;
		text-align: center;
		margin-inline: 5px; 

		${breakpoint.small`
  			font-size: 22px;
			padding-block: 40px  20px;
			margin-bottom: 0.3rem;
		`}
	}

	.row {
		display: flex;
		column-gap: 10px;
		flex-direction: column;
		border-bottom: 1px solid ${colors.gray};
		width: 100%;
		padding-block: 20px;

		${breakpoint.small`
			flex-direction: row;
			width: 92%;
			padding-block: 20px;
			margin: 10px auto;
			align-items: center;
		`}
	}
	
	.column {
		flex: 0;
		min-width: 24%;		

		&.address {
			min-width: 28%;
		}
		
		&.title {
			margin-left: 10px;
			min-width: 28%;
		}

		&.telephone {
			min-width: 17%;
			margin-bottom: 10px;
		}
		
		h4 {
			font-size: 16px;
			text-align: center;
			margin-bottom: 10px;

			${breakpoint.small`
				text-align: left;
			`}
		}		
		
		p {
			font-size: 15px;
			padding: 0 1rem;
			line-height: 1.2rem;
			text-align: center;

			${breakpoint.small`
				text-align: left;
				line-height: 1.4rem;
				font-size: 16px;
			`}
		}
		
		  a {
			color: ${colors.orange};

			&:hover {
			  color: ${colors.charcoal};
			}
		  }	

	   ${ExternalButtonLink} {
			width: 180px;
			color: ${colors.charcoal};
			
			&:hover {
			  color: ${colors.white};
			}
			
		}

				
	}
  `

const GetTested = props =>  (
    <StyledGetTested>
      	<h2>Call to make an appointment</h2>
      <StyledContainer>
      	<h3>Afforable Rapid Testing - 4&nbsp;locations</h3>
		<div className="row">
			<div className="column title">
				<h4>Southeast Valley, Phoenix</h4>
			</div>
			<div className="column address">
				<p>12020 S. Warner Elliot Loop, #124,<br />
				 Phoenix, AZ 85044</p>
			</div>
			<div className="column telephone">
				<p><a href="tel:+1-480-454-3630">(480) 454-3630</a></p>
			</div>
			<div className="column">
				 <ExternalButtonLink href="tel:+1-480-454-3630" target="_blank" rel="noopener noreferrer" backgroundcolor="orange">Call Now</ExternalButtonLink>
			</div>
		</div>
		<div className="row">
			<div className="column title">
				<h4>PHX Airport/Arcadia, Phoenix</h4>
			</div>
			<div className="column address">
				<p>4602 E. McDowell Rd<br />
				 Phoenix, AZ 85008</p>
			</div>
			<div className="column telephone">
				<p><a href="tel:+1-480-454-3630">(480) 454-3630</a></p>
			</div>
			<div className="column">
				 <ExternalButtonLink href="tel:+1-480-454-3630" target="_blank" rel="noopener noreferrer" backgroundcolor="orange">Call Now</ExternalButtonLink>
			</div>
		</div>
		<div className="row">
			<div className="column title">
				<h4>North Scottsdale</h4>
			</div>
			<div className="column address">
				<p>9261 E. Via De Ventura, #102<br />
				 Scottsdale, AZ 85258</p>
			</div>
			<div className="column telephone">
				<p><a href="tel:+1-480-454-3630">(480) 454-3630</a></p>
			</div>
			<div className="column">
				 <ExternalButtonLink href="tel:+1-480-454-3630" target="_blank" rel="noopener noreferrer" backgroundcolor="orange">Call Now</ExternalButtonLink>
			</div>
		</div>
		<div className="row">
			<div className="column title">
				<h4>West Valley</h4>
			</div>
			<div className="column address">
				<p>9241 W. Bell Road, #106<br />
				 Sun City, AZ 85351</p>
			</div>
			<div className="column telephone">
				<p><a href="tel:+1-480-454-3630">(480) 454-3630</a></p>
			</div>
			<div className="column">
				 <ExternalButtonLink href="tel:+1-480-454-3630" target="_blank" rel="noopener noreferrer" backgroundcolor="orange">Call Now</ExternalButtonLink>
			</div>
		</div>
      </StyledContainer>
    </StyledGetTested>
  )

export default GetTested
